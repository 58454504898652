@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Golos+Text&family=Poppins:wght@400;500;600;700;800&display=swap');

.header1{
    @apply font-poppins font-medium text-[96px] leading-[120px] tracking-[0.012em]
}
.header2{
    @apply font-poppins font-medium text-[84px] leading-[100px] tracking-[0.012em]
}
.header3{
    @apply font-poppins font-medium text-[64px] leading-[80px] tracking-[0.012em]
}
.header4{
    @apply font-poppins font-medium text-[48px] leading-[64px] tracking-[0.012em]
}
.header5{
    @apply font-poppins font-medium text-[36px] leading-[42px] tracking-[0.012em]
}
.header6{
    @apply font-poppins font-medium text-[24px] leading-[30px] tracking-[0.012em]
}
.paragraph{
    @apply  font-golos font-normal text-[16px] leading-[16px] tracking-[0.007em]
}